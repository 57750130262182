<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form ref="form" lazy-validation
            @submit.prevent="handleSubmit(saveBenefitConfig)"
            method="post"
            id="check-benefit">
      <v-container>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1">1. {{ addTitle }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider
                name="benefit-title"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
                :name="'benefit-title'"
                v-model="benefit.title"
                :text="benefit.title"
                :label="'Nhập title slider'"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1">2. {{ addVideo }} </span>
          </v-col>
        </v-row>
        <v-row class="v-row">
          <v-col cols="6" sm="6" md="6" class="justify-center text-center">
            <ValidationProvider
                name="video"
                rules="required"
                v-slot="{ errors }"
            >
            <v-file-input
                :rules="rules"
                :name="'video'"
                prepend-icon="mdi-video"
                v-model="video"
                label="Upload video"
                @change="onChangeFileUpload"
            ></v-file-input>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="v-row">
          <v-col>
            <span class="validate-error text-danger">{{ videoError }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="justify-begin text-begin">
            <div id="preview" class="pl-20" v-if="benefit.video_src != ''">
              <video width="400px" controls id="video">
                <source :src="benefit.video_src" type="video/mp4"/>
              </video>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1">3. {{ chooseContent }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-radio-group v-model="benefit.type" row :mandatory="mandatory">
              <v-radio label="Danh sách" :value="listContentType"></v-radio>
              <v-radio label="Html" :value="htmlContentType"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="benefit.type == 1" cols="12">
            <hnr-edit-table
                :content="benefit.list"
                @addRow="addRow"
                @updateData="updateItemTable"
                @deleteItem="deleteItem"
            ></hnr-edit-table>
          </v-col>
          <v-col v-else cols="12">
            <ck-content
                :content="benefit.content"
                @getData="getContent"
            ></ck-content>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-btn color="primary" type="submit" form="check-benefit">{{ save }}</v-btn>
        </v-row>
        <Loading v-if="loading"/>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>
import {mapGetters} from "vuex";
import {UPLOAD_VIDEO} from "@/store/media.module";
import {SAVE_CONF} from "@/store/setting.module";
import {
  HOME_CONF_TOP_BAR_LIST_TYPE,
  HOME_CONF_TOP_BAR_HTML_TYPE
} from "@/common/constants";
import CkContent from "@/components/ck-content";
import HnrEditTable from "@/components/elements/HnrEditTable";
import HnrInput from "@/components/elements/hnr-input";
import Loading from "@/components/loading";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
export default {
  data() {
    return {
      rules: [
        value => !!value || 'Trường này không được bỏ trống.',
      ],
      video: {},
      mandatory: true,
      benefit: {
        type: 1,
        title: "",
        video_src: null,
        content: "",
        list: []
      },
      videoError: "",
      listContentType: HOME_CONF_TOP_BAR_LIST_TYPE,
      htmlContentType: HOME_CONF_TOP_BAR_HTML_TYPE,
      show: false,
      loading: false,
      addTitle: 'Thêm title',
      addVideo: 'Thêm video',
      chooseContent: 'Chọn kiểu nội dung',
      save: 'Lưu'
    };
  },
  mounted() {
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình Trang chủ", titledad: 'Cấu hình'}]);
  },
  computed: {
    ...mapGetters(["videoUrl", "benefitConf"])
  },
  watch: {
    videoUrl() {
      this.benefit.video_src = this.videoUrl;
      if (this.benefit.video_src) {
        this.show = true;
      }
    },
    benefitConf: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.benefit = newVal;
      }
    }
  },
  components: {
    CkContent,
    HnrEditTable,
    HnrInput,
    Loading
  },
  methods: {
     onChangeFileUpload() {
      this.videoError = "";
      let formData = new FormData();
      formData.append("file", this.video);
      this.loading = true
       this.$store.dispatch(UPLOAD_VIDEO, formData).then((data)=>{
         this.loading = false
        if(data.data.status){
          this.benefit.video_src =  data.data.data
          let video = document.getElementById("video");
          video.load();
          video.play();
          this.$toasted.success('Lưu thành công', {
            position: "top-right",
            theme: "toasted-primary",
            duration : 1000
          });
        }
        else {
          this.$toasted.error(data.data.message, {
            position: "top-right",
            theme: "toasted-primary",
            duration : 1000
          });
        }
      });
    },
    clearImage() {
      this.benefit.video_src = "";
      this.show = false;
    },
    getContent(content) {
      this.benefit.content = content;
    },
    updateItemTable(payload) {
      var index = this.benefit.list.findIndex(item => item.id == payload.id);
      this.benefit.list[index] = payload;
    },
    addRow() {
      if (!this.benefit.list) {
        this.benefit.list = [];
      }

      var id = this.benefit.list.length;
      const item = {
        id: id,
        content: ""
      };
      this.benefit.list.push(item);
    },
    deleteItem(item) {
      var index = this.benefit.list.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.benefit.list.splice(index, 1);
        this.reindex();
      }
    },
    reindex() {
      this.benefit.list.forEach((element, index) => {
        element.id = index;
      });
    },
    saveBenefitConfig() {
      let data = {
        key: 'benefit',
        value: this.benefit
      }
      this.$store.dispatch(SAVE_CONF, data).then((data)=>{
        if (data.status) {
          this.$toasted.success('Lưu thành công', {
            position: "top-right",
            theme: "toasted-primary",
            duration : 1000
          });
        }
      });
    }
  }
};
</script>

<style lang="scss"></style>
