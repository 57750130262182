var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"pa-0 ma-0",attrs:{"scrollable":"","max-width":"1000","light":""},on:{"click:outside":_vm.onClickOutside},model:{value:(_vm.showModel),callback:function ($$v) {_vm.showModel=$$v},expression:"showModel"}},[_c('v-card',{staticClass:"pa-0 ma-0"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":"","method":"post","id":"check-save-slider"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveSliderConfig)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("1. "+_vm._s(_vm.addTitleSlide))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","clearable":"","rules":_vm.rules,"name":'title',"text":_vm.slider.title,"placeholder":'Nhập title slider'},model:{value:(_vm.slider.title),callback:function ($$v) {_vm.$set(_vm.slider, "title", $$v)},expression:"slider.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("2. "+_vm._s(_vm.addTitleSlide))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","clearable":"","rules":_vm.rules,"name":'slug',"text":_vm.slider.slug,"placeholder":'Nhập slug khóa học'},model:{value:(_vm.slider.slug),callback:function ($$v) {_vm.$set(_vm.slider, "slug", $$v)},expression:"slider.slug"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" (*) Copy url khóa học ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("3. "+_vm._s(_vm.addImgSlide))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pl-1",attrs:{"rules":_vm.rules,"name":'image',"prepend-icon":"mdi-camera","label":"Tải ảnh","accept":"image/png, image/jpeg, image/bmp"},on:{"change":_vm.onChangeFileUpload,"click:clear":_vm.clearImage},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),(_vm.show)?_c('div',{attrs:{"id":"preview"}},[_c('v-img',{attrs:{"src":_vm.slider.image_src}})],1):_vm._e(),_c('span',{staticClass:"validate-error text-danger"},[_vm._v(_vm._s(_vm.imageError))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("4. "+_vm._s(_vm.chooseContent))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio-group',{attrs:{"row":"","mandatory":_vm.mandatory,"name":"type"},model:{value:(_vm.slider.type),callback:function ($$v) {_vm.$set(_vm.slider, "type", $$v)},expression:"slider.type"}},[_c('v-radio',{attrs:{"label":"Danh sách","value":_vm.listContentType}}),_c('v-radio',{attrs:{"label":"Html","value":_vm.htmlContentType}})],1)],1)],1),_c('v-row',[(_vm.slider.type == 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('hnr-edit-table',{attrs:{"name":'listContent',"content":_vm.slider.slider_list},on:{"addRow":_vm.addRow,"updateData":_vm.updateItemTable,"deleteItem":_vm.deleteItem}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('ck-content',{attrs:{"content":_vm.slider.slider_content},on:{"getData":_vm.getContent}})],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","form":"check-save-slider"}},[_vm._v("Lưu")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }