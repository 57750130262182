import ApiService from "@/common/api.service";
// group question
export const GET_GROUP_QUESTION_EX_DETAIL = "/exerscise-toeic/group-question-detail";
export const SAVE_GROUP_QUESTION_EX = "/exerscise-toeic/group-question-ex/save";
export const UPDATE_GROUP_QUESTION_EX = "/exerscise-toeic/group-question-ex/update";
// export const DELETE_GROUP_QUESTION = "/group-question/delete";
// export const LIST_GROUP_QUESTION_BY_TYPE = "/group-question/list-by-type";
// export const GET_GROUP_QUESTION_TYPE = "/group-question/type";

// export const SET_GROUP_QUESTION_TYPE = "setGroupQuestionType";
//
// export const SET_GROUP_QUESTION_LIST = "setGroupQuestionList";
export const SET_GROUP_QUESTION_DETAIL = "setGroupQuestionDetail";
// export const SET_GROUP_QUESTION_DETAIL_PART = "setGroupQuestionDetailPart";
//
// export const GET_GROUP_QUESTION_LIST_BY_CONFIG = "/group-question/find-by-config";
// export const SET_GROUP_QUESTION_LIST_BY_CONFIG = "setGroupQuestionListByConfig";
//
// export const GET_GROUP_QUESTION_PART = "/group-question/part";
// export const SET_GROUP_QUESTION_PART = "setGroupQuestionListPart";

const state = {
    group_questions: [],
    group_question: {
        audio: {
            src: ""
        },
        content: ""
    },
    type: [],
    group_questions_by_config:[],
    group_questions_part:[],
    group_detail : {},
    group_detail_part : {}
}
// getters
const getters = {
    // groupQuestionList(state) {
    //     return state.group_questions
    // },
    groupQuestionDetail(state) {
        return state.group_question
    },
    // groupQuestionType(state) {
    //     return state.type
    // },
    // groupQuestionListByConfig(state) {
    //     return state.group_questions_by_config
    // },
    // groupQuestionPart(state) {
    //     return state.group_questions_part
    // },
    // group_detail(state) {
    //     return state.group_detail
    // },
    // group_detail_part(state) {
    //     return state.group_detail_part
    // }
}
// actions
const actions = {
    // group question
    async [GET_GROUP_QUESTION_EX_DETAIL](context, payload) {
        return ApiService.query(GET_GROUP_QUESTION_EX_DETAIL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_GROUP_QUESTION_DETAIL, data.data);
                context.commit('getDetailGroupQuestion', data.data);
            }
        })
    },
    // async [GET_GROUP_QUESTION_DETAIL_PART](context, payload) {
    //     return ApiService.query(GET_GROUP_QUESTION_DETAIL_PART, payload).then(({data}) => {
    //         if (data.status) {
    //             //context.commit(SET_GROUP_QUESTION_DETAIL_PART, data.data);
    //             //context.commit('getDetailGroupQuestion', data.data);
    //         }
    //     })
    // },
    // async [LIST_GROUP_QUESTION_BY_TYPE](context, payload) {
    //     return ApiService.query(LIST_GROUP_QUESTION_BY_TYPE, payload).then(({data}) => {
    //         if (data.status) {
    //             context.commit(SET_GROUP_QUESTION_LIST, data.data);
    //         }
    //     })
    // },
    // async [GET_GROUP_QUESTION_TYPE](context, payload) {
    //     return ApiService.query(GET_GROUP_QUESTION_TYPE, payload).then(({data}) => {
    //         if (data.status) {
    //             context.commit(SET_GROUP_QUESTION_TYPE, data.data);
    //         }
    //     })
    // },
    // async [GET_GROUP_QUESTION_LIST_BY_CONFIG](context, payload) {
    //     return ApiService.query(GET_GROUP_QUESTION_LIST_BY_CONFIG, payload).then(({data}) => {
    //         if (data.status) {
    //             context.commit(SET_GROUP_QUESTION_LIST_BY_CONFIG, data.data);
    //         }
    //     })
    // },
    // async [GET_GROUP_QUESTION_PART](context, payload) {
    //     return ApiService.query(GET_GROUP_QUESTION_PART, payload).then(({data}) => {
    //         if (data.status) {
    //             context.commit(SET_GROUP_QUESTION_PART, data.data);
    //         }
    //     })
    // },
    async [SAVE_GROUP_QUESTION_EX](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(SAVE_GROUP_QUESTION_EX, payload).then(({data}) => {
                if (data.status) {
                    resolve(data);
                }
            })
        });
    },
    async [UPDATE_GROUP_QUESTION_EX](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(UPDATE_GROUP_QUESTION_EX, payload).then(({data}) => {
                if (data.status) {
                    resolve(data);
                }
            })
        });
    },
    // async [DELETE_GROUP_QUESTION](context, payload) {
    //     return ApiService.delete(DELETE_GROUP_QUESTION, payload)
    // },
}
// mutations
const mutations = {
    // exam meta
    // [SET_GROUP_QUESTION_LIST](state, data) {
    //     state.group_questions = data;
    // },
    [SET_GROUP_QUESTION_DETAIL](state, data) {
        if (data) {
            state.group_question = data;
            if(!state.group_question.audio){
                state.group_question.audio = {
                    src :""
                }
            }
        } else {
            state.group_question = {
                audio: {
                    src: ""
                },

            }
        }
    },
    // [SET_GROUP_QUESTION_DETAIL_PART](state, data) {
    //     if (data) {
    //         state.group_question = data;
    //         if(!state.group_question.audio){
    //             state.group_question.audio = {
    //                 src :""
    //             }
    //         }
    //     } else {
    //         state.group_question = {
    //             audio: {
    //                 src: ""
    //             },
    //
    //         }
    //     }
    // },
    // [SET_GROUP_QUESTION_TYPE](state, data) {
    //     state.type = data;
    // },
    // [SET_GROUP_QUESTION_LIST_BY_CONFIG](state, data) {
    //     state.group_questions_by_config = data;
    // },
    // [SET_GROUP_QUESTION_PART](state, data) {
    //     state.group_questions_part = data;
    // },
    // getDetailGroupQuestion(state, data) {
    //     state.group_detail = data;
    // },
}
export default {
    state,
    getters,
    actions,
    mutations
}
