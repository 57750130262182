import ApiService from "@/common/api.service";

export const GET_LIST_AFFILIATE_CODE = "/affiliate/list";

export const SET_LIST_AFFILIATE_CODE = "setListAffiliates";

export const GET_INFO_AFILIATE_CODE = "/affiliate/info";

export const SET_INFO_AFILIATE_CODE = "/setInfoAffiliate";

export const GET_UPDATE_AFFILIATE_CODE = "/affiliate/update";


const state = {
    listAffiliate:[],
    info_code: null,
};

const getters = {
    listAffiliates(state) {
        return state.listAffiliate;
    },
    getCodeById(state) {
        return state.info_code;
    }
};

const actions = {
    [GET_LIST_AFFILIATE_CODE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_AFFILIATE_CODE, payload).then(({ data }) => {
                if (data.status) {
                    console.log("get list student action");
                    context.commit(SET_LIST_AFFILIATE_CODE, data);
                }
                resolve(data);
            });
        });
    },
    [GET_INFO_AFILIATE_CODE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_INFO_AFILIATE_CODE, payload).then(({data}) => {
                if (data.status) {
                    console.log("get info code by id");
                    context.commit(SET_INFO_AFILIATE_CODE, data);
                }
                resolve(data);
            });
        });
    },
    [GET_UPDATE_AFFILIATE_CODE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_UPDATE_AFFILIATE_CODE, payload).then(
                ({ data }) => {
                    if (data.status) {
                        console.log("update discount code success");
                    } else {
                        console.log("update discount code fail");
                    }
                    resolve(data);
                }
            );
        });
    },
};

const mutations = {
    [SET_LIST_AFFILIATE_CODE](state, data) {
        state.listAffiliate = data.data;
    },
    [SET_INFO_AFILIATE_CODE](state, data) {
        state.info_code = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
