import ApiService from "@/common/api.service";
// group question
export const GET_QUESTION_DETAIL = "/question";
export const GET_QUESTION_BY_GROUP = "/question/find-by-group";
export const GET_QUESTION_BY_EXAM = "/question/find-by-exam";
export const GET_QUESTION_BY_GROUP_EX = "/question-ex/find-by-group";

export const SAVE_QUESTION = "/question/save";
export const UPDATE_QUESTION = "/question/update";
export const DELETE_QUESTION = "/question/delete";
export const DELETE_QUESTION_AUDIO = "/question/audio/delete";

export const SAVE_QUESTION_EX = "/question-ex/save";
export const GET_QUESTION_DETAIL_EX = "/question-ex";
export const UPDATE_QUESTION_EX = "/question-ex/update";

// delete question group
export const DELETE_QUESTION_GROUP = "/linked-word/delete";


export const SET_QUESTION_DETAIL = "setQuestionDetail";
export const SET_QUESTION_BY_GROUP = "setQuestionByGroup";
export const SET_QUESTION_BY_GROUP_EX = "setQuestionByGroupEx";
export const SET_QUESTION_BY_EXAM = "setQuestionByExam";
export const SET_QUESTION_CHECKED = "setQuestionChecked";
export const SET_SELECTED_QUESTION = "setSelectedQuestion";

const state = {
    questions_by_group: [],
    questions_by_group_ex: [],
    questions_by_exam: [],
    question:{},
    selected_question: [],
}
// getters
const getters = {
    questionListByGroup(state) {
        return state.questions_by_group
    },
    questionListByGroupEx(state) {
        return state.questions_by_group_ex
    },
    questionListByExam(state) {
        return state.questions_by_exam
    },
    questionDetailForExam(state) {
        return state.question
    },
    selectedQuestion(state) {
        return state.selected_question
    },
}
// actions
const actions = {
    async [GET_QUESTION_DETAIL](context, payload) {
        return ApiService.get(GET_QUESTION_DETAIL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_QUESTION_DETAIL, data.data);
            }
        })
    },
    async [GET_QUESTION_DETAIL_EX](context, payload) {
        return ApiService.get(GET_QUESTION_DETAIL_EX, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_QUESTION_DETAIL, data.data);
            }
        })
    },
    async [GET_QUESTION_BY_GROUP](context, payload) {
        return ApiService.query(GET_QUESTION_BY_GROUP, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_QUESTION_BY_GROUP, data.data);
            }
        })
    },
    async [GET_QUESTION_BY_GROUP_EX](context, payload) {
        return ApiService.query(GET_QUESTION_BY_GROUP_EX, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_QUESTION_BY_GROUP_EX, data.data);
            }
        })
    },
    async [GET_QUESTION_BY_EXAM](context, payload) {
        return ApiService.query(GET_QUESTION_BY_EXAM, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_QUESTION_BY_EXAM, data.data);
            }
        })
    },
    // question
    async [SAVE_QUESTION](context, payload) {
        return ApiService.post(SAVE_QUESTION, payload).then((data)=>{
            return data;
        })
    },
    async [SAVE_QUESTION_EX](context, payload) {
        return ApiService.post(SAVE_QUESTION_EX, payload).then((data)=>{
            return data;
        })
    },
    async [UPDATE_QUESTION](context, payload) {
        return ApiService.post(UPDATE_QUESTION, payload)
    },
    async [UPDATE_QUESTION_EX](context, payload) {
        return ApiService.post(UPDATE_QUESTION_EX, payload)
    },
    async [DELETE_QUESTION](context, payload) {
        return ApiService.post(DELETE_QUESTION, payload)
    },
    async [DELETE_QUESTION_AUDIO](context, payload) {
        return ApiService.post(DELETE_QUESTION_AUDIO, payload)
    },
    async [DELETE_QUESTION_GROUP](context, payload){
        return ApiService.post(DELETE_QUESTION_GROUP, payload)
    },

}
// mutations
const mutations = {
    [SET_QUESTION_BY_GROUP](state, data) {
        state.questions_by_group = data;
    },
    [SET_QUESTION_BY_GROUP_EX](state, data) {
        state.questions_by_group_ex = data;
    },
    [SET_QUESTION_BY_EXAM](state, data) {
        state.questions_by_exam = data;
    },
    [SET_QUESTION_DETAIL](state, data) {
        state.question = data;
    },
    [SET_QUESTION_CHECKED](state, data) {
        state.questions_by_group = data;
    },
    [SET_SELECTED_QUESTION](state, data) {
        state.selected_question = data;
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
