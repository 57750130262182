<template>
  <v-dialog
      v-model="showModel"
      scrollable
      @click:outside="onClickOutside"
      max-width="1000"
      light
      class="pa-0 ma-0"
  >
    <v-card class="pa-0 ma-0">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form ref="form" lazy-validation
                @submit.prevent="handleSubmit(saveSliderConfig)"
                method="post"
                id="check-course">
          <v-container>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1">1. {{ addTitle }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                    name="title"
                    rules="required"
                    v-slot="{ errors }"
                >
                <hnr-input
                    :name="'title'"
                    v-model="course.title"
                    :text="course.title"
                    :label="'Nhập title slider'"
                ></hnr-input>
                  <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1">2. Nhập Slug</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                    name="slug"
                    rules="required"
                    v-slot="{ errors }"
                >
                <hnr-input
                    :name="'slug'"
                    v-model="course.slug"
                    :text="course.slug"
                    :label="'Nhập slug khóa học cha'"
                ></hnr-input>
                  <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                (*) Copy url khóa học
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1">3. {{ chooseCourse }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-radio-group
                    v-model="course.type"
                    row
                    :mandatory="mandatory"
                    name="type"
                >
                  <v-radio label="Danh sách" :value="listContentType"></v-radio>
                  <v-radio label="Html" :value="htmlContentType"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="course.type == 1" cols="12">
                <HnrEditTableChooseValue
                    :content="course.course_child"
                    @addRow="addRow"
                    @updateData="updateItemTable"
                    @deleteItem="deleteItem"
                ></HnrEditTableChooseValue>
              </v-col>
              <v-col v-else cols="12">
                <ck-content
                    :content="course.course_child"
                    @getData="getContent"
                ></ck-content>
              </v-col>
            </v-row>
            <v-row class="align-center justify-center">
              <v-btn color="primary" type="submit" form="check-course">{{ save }}</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SAVE_CONF} from "@/store/setting.module";
import {
  HOME_CONF_TOP_BAR_LIST_TYPE,
  HOME_CONF_TOP_BAR_HTML_TYPE
} from "@/common/constants";
import CkContent from "@/components/ck-content";
import HnrEditTableChooseValue from "@/components/elements/HnrEditTableChooseValue";
import HnrInput from "@/components/elements/hnr-input";

export default {
  props: {
    dialog: {type: Boolean, default: false},
    courseId: {type: Number, default: 0}
  },
  data() {
    return {
      mandatory: true,
      image: {},
      course: {
        id: null,
        title: "",
        type: 1,
        course_child: []
      },
      listContentType: HOME_CONF_TOP_BAR_LIST_TYPE,
      htmlContentType: HOME_CONF_TOP_BAR_HTML_TYPE,
      show: false,
      edit: false,
      addTitle: 'Thêm title',
      chooseCourse: 'Chọn khóa học',
      save: 'Lưu'
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tạo nội dung cho Top Slider"}
    ]);
  },
  computed: {
    ...mapGetters([
      "imageUrl",
      "currentCourseMenu",
      "courseMenu",
      "listCourse"
    ]),
    showModel: {
      get: function () {
        return this.dialog;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (newValue) {
      }
    }
  },
  watch: {
    currentCourseMenu: {
      deep: true,
      handler(newVal) {
        this.course = newVal;
        if (
            this.course &&
            this.course.type == HOME_CONF_TOP_BAR_LIST_TYPE &&
            !this.course.course_child
        ) {
          this.course.course_child = [];
        }
      }
    }
  },
  components: {
    CkContent,
    HnrEditTableChooseValue,
    HnrInput
  },
  methods: {
    getContent(content) {
      this.course.course_child = content;
    },
    getDataUpdate(payload){
      this.listCourse.map(course => {
        if (course.id == payload.courseId) {
          payload.title = course.title;
          payload.slug = course.slug;
        }
      })
      return payload;
    },
    updateItemTable(payload) {
      let data = this.getDataUpdate(payload)
      if (this.course && this.course.course_child) {
        var index = this.course.course_child.findIndex(
            item => item.id == data.id
        );
        this.course.course_child[index] = data;
      }
    },
    addRow() {
      if (!this.course.course_child) {
        this.course.course_child = [];
      }

      let id = this.course.course_child.length;
      const item = {
        id: id,
        courseId: null,
        slug: "",
        title: ""
      };
      this.course.course_child.push(item);
    },
    deleteItem(item) {
      var index = this.course.course_child.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.course.course_child.splice(index, 1);
        this.reindex();
      }
    },
    reindex() {
      this.course.course_child.forEach((element, index) => {
        element.id = index;
      });
    },
    checkValue() {
      if (this.course.id === null && this.courseId !== null) {
        this.course.id = this.courseId;
        return true
      }
      return true
    },
    getDataPost() {
      let value = this.courseMenu ? this.courseMenu : [];
      value.map((slide, i) => {
        if (slide.id == this.course.id) {
          value[i] = this.course;
          this.edit = true;
        }
      });
      if (!this.edit) {
        value.push(this.course);
      }
      let data = {
        key: "course_menu",
        value: value
      };
      return data;
    },
    saveSliderConfig() {
      let check = this.checkValue();
      if (check) {
        let data = this.getDataPost()
        this.$store.dispatch(SAVE_CONF, data).then(data => {
          if (data.status) {
            this.resetForm();
            this.$emit("closePopup");
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
          }
        });
        this.edit = false
      }
    },

    onClickOutside() {
      this.resetForm();
      this.$emit("onClickOutside");
    },
    resetForm() {
      this.course = {
        id: null,
        title: "",
        type: 1,
        course_child: []
      };
      this.show = false;
    }
  }
};
</script>
