/* eslint no-use-before-define: 0 */
<template>
  <v-dialog
      v-model="showModel"
      scrollable
      @click:outside="onClickOutside"
      max-width="1000"
      light
      class="pa-0 ma-0"
  >
    <v-card class="pa-0 ma-0">
      <ValidationObserver v-slot="{ handleSubmit }">
      <v-form ref="form" lazy-validation
              @submit.prevent="handleSubmit(saveSliderConfig)"
              method="post"
              id="check-save-slider">
        <v-container>
          <v-row>
            <v-col cols="6">
              <span class="text-body-1">1. {{ addTitleSlide }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ValidationProvider
                  name="title"
                  rules="required"
                  v-slot="{ errors }"
              >
                <v-text-field
                  outlined
                  clearable
                  :rules="rules"
                  :name="'title'"
                  v-model="slider.title"
                  :text="slider.title"
                  :placeholder="'Nhập title slider'"
              ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="text-body-1">2. {{ addTitleSlide }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ValidationProvider
                  name="slug"
                  rules="required"
                  v-slot="{ errors }"
              >
              <v-text-field
                  outlined
                  clearable
                  :rules="rules"
                  :name="'slug'"
                  v-model="slider.slug"
                  :text="slider.slug"
                  :placeholder="'Nhập slug khóa học'"
              ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              (*) Copy url khóa học
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="text-body-1"
              >3. {{ addImgSlide }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ValidationProvider
                  name="image"
                  rules="required"
                  v-slot="{ errors }"
              >
              <v-file-input
                  :rules="rules"
                  :name="'image'"
                  class="pl-1"
                  prepend-icon="mdi-camera"
                  v-model="image"
                  label="Tải ảnh"
                  accept="image/png, image/jpeg, image/bmp"
                  @change="onChangeFileUpload"
                  @click:clear="clearImage"
              ></v-file-input>
              <div id="preview" v-if="show">
                <v-img :src="slider.image_src"></v-img>
              </div>
              <span class="validate-error text-danger">{{ imageError }}</span>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="text-body-1">4. {{ chooseContent }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-radio-group
                  v-model="slider.type"
                  row
                  :mandatory="mandatory"
                  name="type"
              >
                <v-radio label="Danh sách" :value="listContentType"></v-radio>
                <v-radio label="Html" :value="htmlContentType"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="slider.type == 1" cols="12">
              <hnr-edit-table
                  :name="'listContent'"
                  :content="slider.slider_list"
                  @addRow="addRow"
                  @updateData="updateItemTable"
                  @deleteItem="deleteItem"
              ></hnr-edit-table>
            </v-col>
            <v-col v-else cols="12">
              <ck-content
                  :content="slider.slider_content"
                  @getData="getContent"
              ></ck-content>
            </v-col>
          </v-row>
          <v-row class="align-center justify-center">
            <v-btn color="primary" type="submit" form="check-save-slider">Lưu</v-btn>
          </v-row>
        </v-container>
      </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SAVE_CONF} from "@/store/setting.module";
import {
  HOME_CONF_TOP_BAR_LIST_TYPE,
  HOME_CONF_TOP_BAR_HTML_TYPE
} from "@/common/constants";
import CkContent from "@/components/ck-content";
import HnrEditTable from "@/components/elements/HnrEditTable";
import HnrInput from "@/components/elements/hnr-input";

export default {
  props: {
    dialog: {type: Boolean, default: false},
    sliderId: {type: Number, default: 0}
  },
  data() {
    return {
      rules: [
        value => !!value || 'Trường này không được bỏ trống.',
      ],
      mandatory: true,
      image: {},
      slider: {
        id: null,
        title: "",
        slug: "",
        type: 1,
        image_src: null,
        slider_content: "",
        slider_list: []
      },
      imageError: "",
      listContentType: HOME_CONF_TOP_BAR_LIST_TYPE,
      htmlContentType: HOME_CONF_TOP_BAR_HTML_TYPE,
      show: false,
      edit: false,
      addTitleSlide: 'Thêm title Slider',
      addImgSlide: 'Thêm ảnh cho slider (kích thước 760px x 490px)',
      chooseContent: 'Chọn kiểu nội dung'
      //
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tạo nội dung cho Top Slider"}
    ]);
  },
  computed: {
    ...mapGetters(["imageUrl", "currentTopSlider", "topSliderConf"]),
    showModel: {
      get: function () {
        return this.dialog;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (newValue) {
      }
    }
  },
  watch: {
    imageUrl() {
      this.slider.image_src = this.imageUrl;
      if (this.slider.image_src) {
        this.show = true;
      }
    },
    currentTopSlider: {
      deep: true,
      handler(newVal) {
        this.slider = newVal;
        if (
            this.slider &&
            this.slider.type == HOME_CONF_TOP_BAR_LIST_TYPE &&
            !this.slider.slider_list
        ) {
          this.slider.slider_list = [];
        }
        if (this.slider.image_src) {
          this.show = true;
        }
      }
    }
  },
  components: {
    CkContent,
    HnrEditTable,
    HnrInput
  },
  methods: {
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(UPLOAD_IMAGE, formData).then((data) => {
        if (data.data.status == 1) {
          this.$toasted.success('Upload file thành công !!', {
            position: "top-right",
            duration: 3000
          })
        }
        else {
          this.$toasted.error('File chưa đúng định dạng', {
            position: "top-right",
            duration: 3000
          })
        }
      });
    },
    clearImage() {
      this.slider.image_src = "";
      this.show = false;
    },
    getContent(content) {
      this.slider.slider_content = content;
    },
    updateItemTable(payload) {
      if (this.slider && this.slider.slider_list) {
        var index = this.slider.slider_list.findIndex(
            item => item.id == payload.id
        );
        this.slider.slider_list[index] = payload;
      }
    },
    addRow() {
      if (!this.slider.slider_list) {
        this.slider.slider_list = [];
      }

      var id = this.slider.slider_list.length;
      const item = {
        id: id,
        content: ""
      };
      this.slider.slider_list.push(item);
    },
    deleteItem(item) {
      var index = this.slider.slider_list.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.slider.slider_list.splice(index, 1);
        this.reindex();
      }
    },
    reindex() {
      this.slider.slider_list.forEach((element, index) => {
        element.id = index;
      });
    },
    checkData() {
      if (this.slider.id === null && this.sliderId !== null) {
        this.slider.id = this.sliderId;
        return true
      }
      return true
    },
    getDataPost() {
      let value = this.topSliderConf ? this.topSliderConf : [];
      value.map((slide, i) => {
        if (slide.id == this.slider.id) {
          value[i] = this.slider
          this.edit = true
        }
      });
      if (!this.edit) {
        value.push(this.slider)
      }
      let data = {
        key: 'slider_homepage',
        value: value
      }
      return data
    },
    saveSliderConfig() {
      let check = this.checkData()
      if (check) {
        let data = this.getDataPost()
        this.$store.dispatch(SAVE_CONF, data).then(data => {
          if (data.status) {
            this.resetForm();
            this.$emit("closePopup");
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
          }
        });
        this.edit = false
      }
    },
    onClickOutside() {
      this.resetForm();
      this.$emit("onClickOutside");
    },
    resetForm() {
      this.slider = {
        id: null,
        title: "",
        type: 1,
        image_src: null,
        slider_content: "",
        slider_list: []
      };
      this.show = false;
    }
  }
};
</script>
