import Vue from "vue";
import Router from "vue-router";

// import component
import SetingHomepage from "@/views/pages/config/homepage/Setting.vue";
import ListCourse from "@/views/pages/course/List.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue")
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue")
        },
        {
          path: "/course/list",
          name: "course-list",
          component: ListCourse
        },
        {
          path: "/course/create",
          name: "course-create",
          component: () => import("@/views/pages/course/Create.vue")
        },
        {
          path: "/course/",
          name: "course-detail",
          component: () => import("@/views/pages/course/Detail.vue")
        },
        {
          path: "/lesson/select/",
          name: "lesson-select",
          component: () => import("@/views/pages/lesson/SelectType.vue")
        },
        {
          path: "/exercise/select",
          name: "exercise-select",
          component: () => import("@/views/pages/lesson/SelectExerciseType.vue")
        },
        {
          path: "/exam/select",
          name: "exam-select",
          component: () => import("@/views/pages/lesson/SelectExamType.vue")
        },
        {
          path: "/lesson/video/",
          name: "lesson-video",
          component: () => import("@/views/pages/lesson/Video.vue")
        },
        {
          path: "/exercise/detail",
          name: "exercise-detail",
          component: () => import("@/views/pages/lesson/Exercise.vue")
        },
        // exerice
        {
          path: "/exercise/translate/add",
          name: "exercise-tranlate-add",
          component: () =>
            import("@/views/pages/exercise-adding/TranslateExerciseAdding.vue")
        },
        {
          path: "/exercise/listening/add",
          name: "exercise-listening-add",
          component: () =>
            import("@/views/pages/exercise-adding/ListeningExerciseAdding.vue")
        },
        {
          path: "/exercise/writing/add",
          name: "exercise-writing-add",
          component: () =>
            import("@/views/pages/exercise-adding/writingExerciseAdding.vue")
        },
        {
          path: "/exercise/listening-and-choose-answer/add",
          name: "exercise-listening-and-choose-answer-add",
          component: () =>
            import("@/views/pages/exercise-adding/ListeningAndChooseExerciseAdding.vue")
        },
        {
          path: "/exercise/reading/add",
          name: "exercise-reading-add",
          component: () =>
            import("@/views/pages/exercise-adding/readingExerciseAdding.vue")
        },
        {
          path: "/exercise/work-link-group/add",
          name: "exercise-exercise-work-link-group-add",
          component: () =>
            import("@/views/pages/exercise-adding/WordLinkExerciseOneGroupAdding.vue")
        },
        {
          path: "/exercise/translate/filled-word/add",
          name: "exercise-tranlate-filled-word-add",
          component: () =>
            import(
              "@/views/pages/exercise-adding/TranlateExerciseFillWordAdding.vue"
            )
        },
        {
          path: "/exercise/translate/question-word/add",
          name: "exercise-tranlate-question-word-add",
          component: () =>
            import(
              "@/views/pages/exercise-adding/TranlateExerciseQuestionWordAdding.vue"
            )
        },
        {
          path: "/exercise/grammar/add",
          name: "exercise-grammar-add",
          component: () =>
            import(
              "@/views/pages/exercise-adding/GrammarExerciseAdding.vue"
            )
        },
        {
          path: "/exercise/translate/answer/add",
          name: "exercise-tranlate-answer-add",
          component: () =>
            import(
              "@/views/pages/exercise-adding/TranlateExerciseAnswerAdding.vue"
            )
        },
        //
        {
          path: "/lesson/exam/",
          name: "lesson-exam",
          component: () => import("@/views/pages/lesson/Exam.vue")
        },

        {
          path: "/lesson/flashcard/",
          name: "lesson-flashcard",
          component: () => import("@/views/pages/lesson/Flashcard.vue")
        },
        //setting
        {
          path: "/setting/homepage/",
          name: "setting-homepage",
          component: SetingHomepage
        },
        {
          path: "/setting/course-hot",
          name: "setting-course-hot",
          component: () => import("@/views/pages/config/course-hot.vue")
        },
        {
          path: "/setting/policy",
          name: "setting-policy",
          component: () => import("@/views/pages/config/policy.vue")
        },
        {
          path: "/setting/rule",
          name: "setting-rule",
          component: () => import("@/views/pages/config/rule.vue")
        },
        {
          path: "/setting/nori-information",
          name: "setting-nori-information",
          component: () => import("@/views/pages/config/nori-information.vue")
        },
        {
          path: "/setting/teacher",
          name: "setting-teacher",
          component: () => import("@/views/pages/config/teacher.vue")
        },
        {
          path: "/setting/support",
          name: "setting-support",
          component: () => import("@/views/pages/config/support.vue")
        },
        {
          path: "/setting/slide-book",
          name: "setting-slide-book",
          component: () => import("@/views/pages/config/slide-book.vue")
        },
        {
          path: "setting/check-scores",
          name: "setting-check-scores",
          component: () => import("@/views/pages/config/check-scores.vue")
        },
        //mark exam writting
        {
          path: "exam/markExam/examByScheduleList",
          name: "exam-by-schedule-list",
          component: () => import("@/views/pages/mark-exam/exam-by-schedule-list.vue")
        },
        {
          path: "exam/markExam/studentByExamList",
          name: "student-by-exam-list",
          component: () => import("@/views/pages/mark-exam/student-by-exam-list.vue")
        },
        {
          path: "exam/markExam/markExam",
          name: "mark-exam",
          component: () => import("@/views/pages/mark-exam/mark-exam.vue")
        },
        //__________________________
        // Questions and exam questions
        //________________________________

        {
          path: "/exam/index",
          name: "exam-index",
          component: () => import("@/views/pages/exam/index.vue")
        },
        {
          path: "/exam/information",
          name: "exam-information",
          component: () => import("@/views/pages/exam/exam-information.vue")
        },
        {
          path: "/exam/select-type",
          name: "exam-select-type",
          component: () => import("@/views/pages/exam/select-type.vue")
        },
        {
          path: "/exam/select-question",
          name: "exam-select-question",
          component: () => import("@/views/pages/exam/select-question.vue")
        },
        {
          path: "/exam/list",
          name: "exam-list",
          component: () => import("@/views/pages/exam/exam-list.vue")
        },
        {
          path: "/exam/list/part",
          name: "exam-list-part",
          component: () => import("@/views/pages/part/exam-list-part.vue")
        },
        {
          path: "/exam/list/part-edit/:id",
          name: "exam-edit-part",
          component: () => import("@/views/pages/part/part-edit.vue")
        },
        {
          path: "/exam/schedule/list",
          name: "exam-schedule-list",
          component: () => import("@/views/pages/exam/exam-schedule-list.vue")
        },
        {
          path: "/exam/select-config",
          name: "exam-select-config",
          component: () => import("@/views/pages/exam/select-config.vue")
        },
        {
          path: "/group-question/list",
          name: "group-question-list",
          component: () => import("@/views/pages/group-question/list.vue")
        },
        {
          path: "/group-question/detail",
          name: "group-question-detail",
          component: () => import("@/views/pages/group-question/detail.vue")
        },
        {
          path: "/question/list",
          name: "question-list",
          component: () => import("@/views/pages/question/list.vue")
        },
        {
          path: "/question/detail",
          name: "question-detail",
          component: () => import("@/views/pages/question/detail.vue")
        },
        // order
        {
          path: "/order/list",
          name: "order-list",
          component: () => import("@/views/pages/order/list.vue")
        },
        {
          path: "/order/list-book",
          name: "order-list-book",
          component: () => import("@/views/pages/order/list-book.vue")
        },
        {
          path: "/order/detail/:id",
          name: "order-detail",
          component: () => import("@/views/pages/order/detail.vue")
        },
        {
          path: "/order/detail-book/:id",
          name: "order-detail-book",
          component: () => import("@/views/pages/order/detail-book.vue")
        },
        //new
        {
          path: "/new/detail",
          name: "new-detail",
          component: () => import("@/views/pages/new/detail")
        },
        {
          path: "/new/create",
          name: "new-create",
          component: () => import("@/views/pages/new/create")
        },
        {
          path: "/new/form",
          name: "new-form",
          component: () => import("@/views/pages/new/form")
        },
        {
          path: "/new/list",
          name: "new-list",
          component: () => import("@/views/pages/new/list")
        },
          //exercise
        {
          path: "/exercise/list",
          name: "exercise-list",
          component: () => import("@/views/pages/exercise/list")
        },
        {
          path: "/exercise/create",
          name: "exercise-create",
          component: () => import("@/views/pages/exercise/Create.vue")
        },
        {
          path: "/exercise/",
          name: "course-detail",
          component: () => import("@/views/pages/exercise/Detail.vue")
        },
        {
          path: "/exercise/group-question-detail",
          name: "group-question-detail",
          component: () => import("@/views/pages/exercise/group-question-detail.vue")
        },
          //book
        {
          path: "/book/list",
          name: "book-list",
          component: () => import("@/views/pages/book/list")
        },
        {
          path: "/book/create",
          name: "book-create",
          component: () => import("@/views/pages/book/create")
        },
        {
          path: "/book/detail/:id",
          name: "book-detail",
          component: () => import("@/views/pages/book/detail")
        },
          // promotion code
        {
          path: "promotion/create",
          name: "promotion-create",
          component: () => import("@/views/pages/promotionCode/createdForm.vue")
        },
        {
          path: "promotion/update/:id",
          name: "promotion-update",
          component: () => import("@/views/pages/promotionCode/updatedForm.vue")
        },
        {
          path: "promotion/list",
          name: "promotion-list",
          component: () => import("@/views/pages/promotionCode/list.vue")
        },

        //users
        {
          path: "/user/student/:id/info",
          name: "user-detail",
          component: () => import("@/views/pages/user/detail_student/index.vue")
        },
        {
          path: "/afflificate/create-code",
          name: "afflificate-create",
          component: () => import("@/views/pages/user/student/createdFormAffiliateCode.vue")
        },
        {
          path: "/afflificate/edit",
          name: "afflificate-update",
          component: () => import("@/views/pages/afflificate/updateFormAffiliateCode.vue")
        },
        {
          path: "/afflificate/list",
          name: "affilicate-list",
          component: () => import("@/views/pages/afflificate/list")
        },
        {
          path: "/afflificate/detail/:code_id",
          name: "afflificate-detail",
          component: () => import("@/views/pages/afflificate/detail")
        },
        {
          path: "/user/teacher/:id/info",
          name: "user-detail",
          component: () => import("@/views/pages/user/detail_teacher/index.vue")
        },
        {
          path: "/user/student/:id/course",
          name: "student-course",
          component: () =>
            import("@/views/pages/user/detail_student/course.vue")
        },
        {
          path: "/user/student/:id/takeExam",
          name: "student-take-exam",
          component: () =>
              import("@/views/pages/user/detail_student/takeExam.vue")
        },
        {
          path: "/user/student/:id/OneOneCourse",
          name: "student-one-on-one-course",
          component: () =>
            import("@/views/pages/user/detail_student/OneOneCourse.vue")
        },
        {
          path: "/user/teacher/:id/OneOneCourse",
          name: "student-one-on-one-course",
          component: () =>
            import("@/views/pages/user/detail_teacher/OneOneCourse.vue")
        },
        {
          path: "/student/list",
          name: "student-list",
          component: () => import("@/views/pages/user/student/Student.vue")
        },
        {
          path: "/teacher/list",
          name: "teacher-list",
          component: () => import("@/views/pages/user/teacher/Teacher.vue")
        },
        {
          path: "/admin/list",
          name: "admin-list",
          component: () => import("@/views/pages/user/admin/Admin.vue")
        },
        {
          path: "/user-gift/list",
          name: "admin-list",
          component: () => import("@/views/pages/user/user-gift/list.vue")
        },

        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/views/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/views/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/views/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/views/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/views/pages/wizard/Wizard-4.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/vuetify",
      name: "vuetify",
      component: () => import("@/views/pages/vuetify/Vuetify.vue"),
      children: [
        {
          path: "alerts",
          name: "vuetify-alerts",
          component: () => import("@/views/pages/vuetify/Alerts.vue")
        },
        {
          path: "avatars",
          name: "vuetify-avatars",
          component: () => import("@/views/pages/vuetify/Avatars.vue")
        },
        {
          path: "badges",
          name: "vuetify-badges",
          component: () => import("@/views/pages/vuetify/Badges.vue")
        },
        {
          path: "buttons",
          name: "vuetify-buttons",
          component: () => import("@/views/pages/vuetify/Buttons.vue")
        },
        {
          path: "calendars",
          name: "vuetify-calendars",
          component: () => import("@/views/pages/vuetify/Calendars.vue")
        },
        {
          path: "cards",
          name: "vuetify-cards",
          component: () => import("@/views/pages/vuetify/Cards.vue")
        },
        {
          path: "chips",
          name: "vuetify-chips",
          component: () => import("@/views/pages/vuetify/Chips.vue")
        },
        {
          path: "dialog",
          name: "vuetify-dialog",
          component: () => import("@/views/pages/vuetify/Dialog.vue")
        },
        {
          path: "autocompletes",
          name: "vuetify-autocompletes",
          component: () =>
            import("@/views/pages/vuetify/forms/Autocompletes.vue")
        },
        {
          path: "file-inputs",
          name: "vuetify-file-inputs",
          component: () => import("@/views/pages/vuetify/forms/FileInputs.vue")
        },
        {
          path: "forms",
          name: "vuetify-forms",
          component: () => import("@/views/pages/vuetify/forms/Forms.vue")
        },
        {
          path: "selection-controls",
          name: "vuetify-selection-controls",
          component: () =>
            import("@/views/pages/vuetify/forms/SelectionControls.vue")
        },
        {
          path: "selects",
          name: "vuetify-selects",
          component: () => import("@/views/pages/vuetify/forms/Selects.vue")
        },
        {
          path: "textareas",
          name: "vuetify-textareas",
          component: () => import("@/views/pages/vuetify/forms/Textareas.vue")
        },
        {
          path: "text-fields",
          name: "vuetify-text-fields",
          component: () => import("@/views/pages/vuetify/forms/TextFields.vue")
        },
        {
          path: "simple-tables",
          name: "vuetify-simple-tables",
          component: () =>
            import("@/views/pages/vuetify/tables/SimpleTables.vue")
        },
        {
          path: "data-tables",
          name: "vuetify-data-tables",
          component: () => import("@/views/pages/vuetify/tables/DataTables.vue")
        },
        {
          path: "tabs",
          name: "vuetify-tabs",
          component: () => import("@/views/pages/vuetify/Tabs.vue")
        },
        {
          path: "timelines",
          name: "vuetify-timelines",
          component: () => import("@/views/pages/vuetify/Timelines.vue")
        },
        {
          path: "tooltips",
          name: "vuetify-tooltips",
          component: () => import("@/views/pages/vuetify/Tooltips.vue")
        },
        {
          path: "treeview",
          name: "vuetify-treeview",
          component: () => import("@/views/pages/vuetify/Treeview.vue")
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/views/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
