import ApiService from "@/common/api.service";
import {GET_DETAIL_BOOK, SET_DETAIL_BOOK} from "./book.module";
import {DELETE_GROUP_QUESTION} from "./group-questions.module";

// exam meta
export const SAVE_EXAM_META = "/exam/meta/save";
export const UPDATE_EXAM_META = "/exam/meta/update";
export const DELETE_EXAM_META = "/exam/meta/delete";


export const GET_EXAM_META_DETAIL = "/exam/meta/detail";
export const SET_EXAM_META_DETAIL = "setExamMetaDetail";

export const GET_EXAM_META_LIST = "/exam/meta/list";
export const SET_EXAM_META_LIST = "setExamMetaList";

// exam schedule
export const SAVE_EXAM_SCHEDULE = "/exam/schedule/save";
export const UPDATE_EXAM_SCHEDULE = "/exam/schedule/update";
export const DELETE_EXAM_SCHEDULE = "/exam/schedule/delete";

export const GET_EXAM_SCHEDULE_LIST = "/exam/schedule/list";
export const SET_EXAM_SCHEDULE_LIST = "setExamCalendarList";
// exam
export const SAVE_EXAM_QUESTION = "/exam-question/save";
export const SAVE_EXAM = "/exam/save";
export const UPDATE_EXAM = "/exam/update";
export const GENERATE_EXAM = "/exam/generate";
export const DELETE_EXAM = "/exam/delete";
export const GET_TAKE_EXAM_LIST_USER = "/take-exam/list/user";
export const GET_EXAM_LIST = "/exam/list";
export const SET_EXAM_LIST = "setExamList";

export const GET_EXAM_DETAIL = "/exam/detail";
export const SET_EXAM_DETAIL = "setExamDetail";
//exam config
export const GET_ALL_EXAM_CONFIG = "exam/config/all";
export const SET_ALL_EXAM_CONFIG = "exam/config/all";
export const GET_EXAM_CONFIG = "exam/config";
export const SET_EXAM_CONFIG = "exam/config";
export const GET_EXAM_CONFIG_BY_LEVEL = "exam/configs/find-by-level";
export const SET_EXAM_CONFIG_BY_LEVEL = "setExamConfigByLevel";
export const SET_CHECKED_QUESTION = "setCheckedQuestion";
export const SET_GROUP_QUESTION = "setGroupQuestion";
//exam part
export const GET_LIST_PART = "exam/part/list";
export const SET_LIST_PART = "setListPart";

export const GET_DETAIL_PART = "/exam/part/detail";
export const SET_DETAIL_PART = "setDetailPart";

export const UPDATE_PART = "/exam/part/update";
export const DELETE_PART = "/exam/part/delete";
//mark exam writting
export const GET_LIST_EXAM_BY_SCHEDULE = "/mark-exam/exam-by-schedule-list";
export const SET_LIST_EXAM_BY_SCHEDULE = "setExamBySchedule";
export const GET_LIST_STUDENT_ẸNJOY_EXAM = "/mark-exam/student-by-exam-list";
export const SET_LIST_STUDENT_ẸNJOY_EXAM = "setListStudentEnjoyExam";
export const GET_QUESTION_REVIEW_EXAM = "/review-exam/question/list";
export const SET_QUESTION_REVIEW_EXAM = "setQuestionReviewExam";
export const UPDATE_SCORE_EXAM_WRITTING = "/mark-exam/get-score";
export const GET_TAKE_EXAM_DETAIL = "/mark-exam/take_exam_detail";
export const SET_TAKE_EXAM_DETAIL = "setTakeExamDetail";
export const SET_TAKE_EXAM_LIST = "setTakeExamList";
// upload file
export const UPLOAD_FILE = "/exam/upload";

const defaultData = {
    exam_meta: {
        title: "",
        slug: "",
        question_number: null,
        start_date_time: "",
        status: "",
        level: "",
    }
}
const state = {
    take_exam_list:[],
    exam_meta: {},
    exam_metas: [],
    exam_schedules: [],
    exams: [],
    exam: {},
    question: {},
    questions: [],
    group_question: {},
    exam_configs: [],
    exam_config: {},
    exam_configs_by_level: [],
    checked_questions: [],
    listExamByShedule: [],
    listStudentEnjoyExam: [],
    review_exam: [],
    take_exam: [],
    list_parts: [],
    list_part: {
        audio: {
            src: ""
        }
    },
}
// getters
const getters = {
    // exam meta
    examMetaDetail(state) {
        return state.exam_meta
    },
    examMetaList(state) {
        return state.exam_metas
    },
    //list part
    listPart(state) {
        return state.list_parts
    },
    detailPart(state) {
        return state.list_part
    },
    // exam schedule
    examScheduleList(state) {
        return state.exam_schedules
    },

    // exam
    examList(state) {
        return state.exams
    },
    examDetail(state) {
        return state.exam
    },
    examConfigList(state) {
        return state.exam_configs
    },
    examConfigDetail(state) {
        return state.exam_config
    },
    examConfigListByLevel(state) {
        return state.exam_configs_by_level
    },
    checked_questions(state) {
        return state.checked_questions
    },
    //mark exam writting
    getListExamByShedule(state) {
        return state.listExamByShedule
    },
    getListStudentEnjoyExam(state) {
        return state.listStudentEnjoyExam
    },
    getListQuestionReview(state) {
        return state.review_exam
    },
    getTakeExamDetail(state) {
        return state.take_exam
    },
    getTakeExamListUser(state) {
        return state.take_exam_list
    }
}
// actions
const actions = {
    //list part
    async [GET_LIST_PART](context, payload) {
        return ApiService.query(GET_LIST_PART, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_LIST_PART, data);
            }
        })
    },
    async [GET_DETAIL_PART](context, payload) {
        return ApiService.query(GET_DETAIL_PART, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_DETAIL_PART, data);
            }
        })
    },
    async [UPDATE_PART](context, payload) {
        return ApiService.post(UPDATE_PART, payload)
    },
    async [DELETE_PART](context, payload) {
        return ApiService.post(DELETE_PART, payload)
    },
    // exam meta
    async [SAVE_EXAM_QUESTION](context, payload) {
        return ApiService.post(SAVE_EXAM_QUESTION, payload).then(({data}) => {
            return data;
        })
    },
    async [GET_EXAM_META_DETAIL](context, payload) {
        return ApiService.query(GET_EXAM_META_DETAIL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_META_DETAIL, data);
            }
        })
    },
    async [GET_EXAM_META_LIST](context, payload) {
        return ApiService.query(GET_EXAM_META_LIST, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_META_LIST, data);
            }
        })
    },
    async [SAVE_EXAM_META](context, payload) {
        return new Promise(resolve => {
            ApiService.post(SAVE_EXAM_META, payload).then(({data}) => {
                resolve(data);
            });
        });
    },
    async [UPDATE_EXAM_META](context, payload) {
        return ApiService.post(UPDATE_EXAM_META, payload)
    },
    async [DELETE_EXAM_META](context, payload) {
        return ApiService.post(DELETE_EXAM_META, payload)
    },
    // exam schedule
    async [GET_EXAM_SCHEDULE_LIST](context, payload) {
        return ApiService.query(GET_EXAM_SCHEDULE_LIST, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_SCHEDULE_LIST, data);
            }
        })
    },
    async [GET_TAKE_EXAM_LIST_USER](context,payload) {
        return ApiService.query(GET_TAKE_EXAM_LIST_USER, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_TAKE_EXAM_LIST, data);
            }
        })
    },
    async [SAVE_EXAM_SCHEDULE](context, payload) {
        return ApiService.post(SAVE_EXAM_SCHEDULE, payload)
    },
    async [UPDATE_EXAM_SCHEDULE](context, payload) {
        return ApiService.post(UPDATE_EXAM_SCHEDULE, payload)
    },
    async [DELETE_EXAM_SCHEDULE](context, payload) {
        return ApiService.post(DELETE_EXAM_SCHEDULE, payload)
    },

    // exam
    async [GET_EXAM_LIST](context, payload) {
        return ApiService.query(GET_EXAM_LIST, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_LIST, data);
            }
        })
    },
    async [GET_EXAM_DETAIL](context, payload) {
        return ApiService.query(GET_EXAM_DETAIL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_DETAIL, data);
            }
        })
    },
    async [SAVE_EXAM](context, payload) {
        return ApiService.post(SAVE_EXAM, payload).then(({data}) => {
            return data
        })
    },
    async [UPDATE_EXAM](context, payload) {
        return ApiService.post(UPDATE_EXAM, payload)
    },
    async [GENERATE_EXAM](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(GENERATE_EXAM, payload)
                .then(({data}) => {
                    resolve(data)
                })

        });
    },
    async [DELETE_EXAM](context, payload) {
        return ApiService.delete(DELETE_EXAM, payload).then(({data}) => {
            return data
        })
    },
    // exam config
    async [GET_ALL_EXAM_CONFIG](context, payload) {
        return ApiService.query(GET_ALL_EXAM_CONFIG, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_ALL_EXAM_CONFIG, data);
            }
        })
    },
    async [GET_EXAM_CONFIG](context, payload) {
        return ApiService.get(GET_EXAM_CONFIG, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_CONFIG, data);
            }
        })
    },
    async [GET_EXAM_CONFIG_BY_LEVEL](context, payload) {
        return ApiService.query(GET_EXAM_CONFIG_BY_LEVEL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_EXAM_CONFIG_BY_LEVEL, data);
            }
        })
    },
    // upload file
    async [UPLOAD_FILE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(UPLOAD_FILE, payload).then(({data}) => {
                resolve(data);
            });
        });
    },
    //mark exam writting
    async [GET_LIST_EXAM_BY_SCHEDULE](context, payload) {
        return ApiService.query(GET_LIST_EXAM_BY_SCHEDULE, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_LIST_EXAM_BY_SCHEDULE, data);
            }
        })
    },
    async [GET_LIST_STUDENT_ẸNJOY_EXAM](context, payload) {
        return ApiService.query(GET_LIST_STUDENT_ẸNJOY_EXAM, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_LIST_STUDENT_ẸNJOY_EXAM, data);
            }
        })
    },
    [GET_DETAIL_PART](context, id) {
        return new Promise(resolve => {
            ApiService.query(`${GET_DETAIL_PART}/${id}`).then(({data}) => {
                if (data.status) {
                    context.commit(SET_DETAIL_PART, data);
                }
                resolve(data);
            });
        });
    },
    async [UPDATE_SCORE_EXAM_WRITTING](context, payload) {
        return ApiService.query(UPDATE_SCORE_EXAM_WRITTING, payload).then(({data}) => {
            return data.data
        })
    },
    async [GET_TAKE_EXAM_DETAIL](context, payload) {
        return ApiService.query(GET_TAKE_EXAM_DETAIL, payload).then(({data}) => {
            if (data.status) {
                context.commit(SET_TAKE_EXAM_DETAIL, data);
            }
        })
    },
}
// mutations
const mutations = {
    //list part
    [SET_LIST_PART](state, data) {
        if (data.data != null) {
            state.list_parts = data.data;
        }
    },
    [SET_DETAIL_PART](state, data) {
        if (data.data != null) {
            state.list_part = data.data;
            if(!state.list_part.audio) {
                state.list_part.audio = ""
            }
        } else {
            state.list_part = {
                audio: {
                    src:""
                },

            }
        }
        // if (data.data != null) {
        //     state.list_part = data.data;
        // }
    },
    // exam meta
    [SET_EXAM_META_DETAIL](state, data) {
        if (data.data != null) {
            state.exam_meta = data.data;
        } else {
            state.exam_meta = Object.assign({}, defaultData.exam_meta)
        }
    },
    [SET_EXAM_META_LIST](state, data) {
        if (data.data != null) {
            state.exam_metas = data.data;
        }
    },
    [SET_CHECKED_QUESTION](state, data) {
        state.checked_questions = data
    },
    [SET_GROUP_QUESTION](state, data) {
        state.group_question = data
    },
    // exam meta
    [SET_EXAM_SCHEDULE_LIST](state, data) {
        if (data.data != null) {
            state.exam_schedules = data.data;
        }
    },
    // exam
    [SET_EXAM_LIST](state, data) {
        if (data.data != null && data.data.length > 0) {
            state.exams = data.data

        } else {
            state.exams = [];
        }
    },
    [SET_EXAM_DETAIL](state, data) {
        if (data.data != null) {
            state.exam = data.data;
        }
    },
    [SET_ALL_EXAM_CONFIG](state, data) {
        state.exam_configs = data.data;
    },
    [SET_EXAM_CONFIG](state, data) {
        state.exam_config = data.data;
    },
    [SET_EXAM_CONFIG_BY_LEVEL](state, data) {
        state.exam_configs_by_level = data.data;
    },
    //mark exam writting
    [SET_LIST_EXAM_BY_SCHEDULE](state, data) {
        state.listExamByShedule = data.data;
    },
    [SET_LIST_STUDENT_ẸNJOY_EXAM](state, data) {
        state.listStudentEnjoyExam = data.data;
    },
    [SET_QUESTION_REVIEW_EXAM](state, data) {
        state.review_exam = data.data;
    },
    [SET_TAKE_EXAM_DETAIL](state, data) {
        state.take_exam = data.data;
    },
    [SET_TAKE_EXAM_LIST](state, data) {
        state.take_exam_list = data.data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
