<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form ref="form" lazy-validation
            @submit.prevent="handleSubmit(saveFooterConfig)"
            method="post"
            id="check-footer">
      <v-container>
        <v-row class="align-start justify-start">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{linkFace}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="facebook_link"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
              :name="'facebook_link'"
              v-model="footer.facebook"
              :text="footer.facebook"
              :label="'Nhập đường dẫn trang Facebook'"
              @input="updateFooterConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-start justify-start pa-0 footer">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{linkYoutobe}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="youtube_link"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
              :name="'youtube_link'"
              v-model="footer.youtube"
              :text="footer.youtube"
              :label="'Nhập đường dẫn trang youtube'"
              @input="updateFooterConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-start justify-start pa-0 footer">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{linkInstagram}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="instagram_link"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
              :name="'instagram_link'"
              v-model="footer.instagram"
              :text="footer.instagram"
              :label="'Nhập đường dẫn trang Instagram'"
              @input="updateFooterConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-start justify-start pa-0 footer">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{linkPrinterest}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="printerest_link"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
              :name="'printerest_link'"
              v-model="footer.printerest"
              :text="footer.printerest"
              :label="'Nhập đường dẫn trang Printerest'"
              @input="updateFooterConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-start justify-start pa-0 footer">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{linkZalo}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="zalo_link"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
              :name="'zalo_link'"
              v-model="footer.zalo"
              :text="footer.zalo"
              :label="'Nhập đường dẫn trang Zalo'"
              @input="updateFooterConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-start justify-start pa-0 footer">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{titleText}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="title"
                rules="required"
                v-slot="{ errors }"
            >
            <hnr-input
              v-model="footer.title"
              :text="footer.title"
              :label="'Title'"
              @input="updateFooterConf"
            ></hnr-input>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-start justify-start pa-0 footer">
          <v-col cols="2" class="pa-0">
            <span class="text-body-1"><b>{{descriptionText}}</b></span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <ValidationProvider
                name="descriptionText"
                rules="required"
                v-slot="{ errors }"
            >
            <v-textarea
                :name="descriptionText"
              v-model="footer.description"
              dense
              outlined
              placeholder="Description"
            ></v-textarea>
              <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-btn color="primary" type="submit" form="check-footer">{{save}}</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import HnrInput from "@/components/elements/hnr-input";
import {SAVE_CONF} from "@/store/setting.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  props: {
    type: { type: String, default: "" },
    text: { type: String, default: "" }
  },
  data() {
    return {
      footer: {
        facebook: "",
        youtube: "",
        instagram: "",
        printerest: "",
        zalo: "",
        title: "",
        description: "",
      },
      save : 'Lưu',
      titleText : 'Title',
      descriptionText : 'Mô tả',
      linkFace : 'Link Facebook Page',
      linkZalo : 'Link Zalo',
      linkYoutobe : 'Link Youtube Page',
      linkInstagram : 'Link Instagram',
      linkPrinterest : 'Link Printerest',
    };
  },
  computed: {
    ...mapGetters(["footerConf"])
  },
  watch: {
    footerConf: {
      deep: true,
      immediate: true,
      handler: function(newVal) {
        this.footer = newVal;
      }
    }
  },
  components: {
    HnrInput
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình Trang chủ", titledad: 'Cấu hình'}]);
  },
  methods: {
    updateFooterConf() {
      // this.$store.dispatch(UPDATE_FOOTER_CONF, this.footer);
    },
    saveFooterConfig() {
      let data = {
        key : 'footer',
        value : this.footer
      }
      this.$store.dispatch(SAVE_CONF, data).then((data)=>{
        if (data.status) {
          this.$toasted.success('Lưu thành công', {
            position: "top-right",
            theme: "toasted-primary",
            duration : 1000
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.footer {
  margin-top: 20px !important;
}
</style>
