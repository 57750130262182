import ApiService from "@/common/api.service";
import {DELETE_GROUP_QUESTION} from "@/store/group-questions.module";
//news store
// action types
export const SAVE_EXERCISE = "/exerscise-toeic/save";
export const UPDATE_EXERCISE = "/exerscise-toeic/update";
export const DELETE_EXERCISE = "/exerscise-toeic/delete";
export const GET_LIST_EXERCISE = "/exerscise-toeic/list";
export const SET_LIST_EXERCISE = "exerciseList";
export const GET_DETAIL_EXERCISE = "/exerscise-toeic/detail";
export const SET_DETAIL_EXERCISE = "exerciseDetail";
export const GET_LIST_ALL_GROUP_QUESTION = "/exerscise-toeic/list-group";
export const SET_LIST_ALL_GROUP_QUESTION = "getListAllGroupQuestion";
export const SAVE_GROUP_QUESTION = "/exerscise-toeic/save-group-question";
export const LIST_GROUP_BY_EXERCISE = "/exerscise-toeic/list-group-by-exercise";
export const SET_LIST_GROUP_BY_EXERCISE = "listGroupByExercises";
export const DELETE_GROUP_QUESTION_EX = "/exerscise-toeic/group-question_ex/delete";


const state = {
  listExercise: [],
  detailExercise: {
    color:'',
    title:'',
    slug:'',
    description:'',
  },
  ListAllGroupQuestion: [],
  listGroupByExercise: [],
};

// getters
const getters = {
   getListExercise(state) {
     return state.listExercise;
   },
   getDetailExercise(state) {
     return state.detailExercise;
   },
  getAllGroupQuestion(state) {
     return state.ListAllGroupQuestion;
  },
  getListGroupByExercise(state) {
     return state.listGroupByExercise;
  }
};

// actions
const actions = {
  [GET_LIST_EXERCISE](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_EXERCISE, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_LIST_EXERCISE, data);
        }
        resolve(data);
      });
    });
  },

  [GET_DETAIL_EXERCISE](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_DETAIL_EXERCISE, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_DETAIL_EXERCISE, data);
        } else {
          context.commit('getDetailFail');
        }
        resolve(data);
      });
    });
  },

  [SAVE_EXERCISE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(SAVE_EXERCISE, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [UPDATE_EXERCISE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(UPDATE_EXERCISE, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },
  [GET_LIST_ALL_GROUP_QUESTION](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_ALL_GROUP_QUESTION, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_LIST_ALL_GROUP_QUESTION, data);
        }
        resolve(data);
      });
    });
  },
  //
  async [DELETE_EXERCISE](context, payload) {
    return ApiService.delete(DELETE_EXERCISE, payload)
  },
  [SAVE_GROUP_QUESTION](context, payload) {
    return new Promise(resolve => {
      ApiService.post(SAVE_GROUP_QUESTION, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },
  [LIST_GROUP_BY_EXERCISE](context, payload) {
    return new Promise(resolve => {
      ApiService.query(LIST_GROUP_BY_EXERCISE, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_LIST_GROUP_BY_EXERCISE, data);
        }
        resolve(data);
      });
    });
  },
  async [DELETE_GROUP_QUESTION_EX](context, payload) {
    return ApiService.delete(DELETE_GROUP_QUESTION_EX, payload)
  },
};

// mutations
const mutations = {
  [SET_LIST_EXERCISE](state, data) {
    state.listExercise = data.data;
  },
  [SET_DETAIL_EXERCISE](state, data) {
    state.detailExercise = data.data;
  },
  [SET_LIST_ALL_GROUP_QUESTION](state, data) {
    state.ListAllGroupQuestion = data.data;
  },
  [SET_LIST_GROUP_BY_EXERCISE](state, data) {
    state.listGroupByExercise = data.data;
  },
  getDetailFail(state) {
    state.detailExercise.color = '';
    state.detailExercise.description = '';
    state.detailExercise.title = '';
    state.detailExercise.slug = '';
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
