import ApiService from "@/common/api.service";

export const CREATE_DISCOUNT_CODE = "/discount-codes/create";
export const GENERATE_CODE = "/discount-codes/generate";
export const UPDATE_DISCOUNT_CODE = "/discount-codes/update";
export const GET_LIST_DISCOUNT_CODE = "/discount-codes/index";
export const DELETE_DISCOUNT_CODE = "/discount-codes/destroy";
export const GET_DETAIL_DISCOUNT_CODE = "/discount-codes/edit";

export const SET_LIST_DISCOUNT_CODE = "setList";
export const SET_DISCOUNT_CODE = "setCode";
export const SET_DETAIL_DISCOUNT_INFO = "setDetailDiscountCode";

const state = {
  list: [],
  code: "",
  codeDiscountInfo: {}
};

const getters = {
  getListDiscountCode(state) {
    return state.list;
  },
  getCode(state) {
    return state.code;
  },
  getDetailDiscountCode(state) {
    return state.codeDiscountInfo;
  }
};

const actions = {
  [GET_LIST_DISCOUNT_CODE](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_DISCOUNT_CODE, payload).then(({ data }) => {
        if (data.data) {
          context.commit(SET_LIST_DISCOUNT_CODE, data.data);
          console.log("get list discount code success");
        } else {
          console.log("get list discount code fail");
        }
        resolve(data);
      });
    });
  },
  [CREATE_DISCOUNT_CODE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(CREATE_DISCOUNT_CODE, payload).then(({ data }) => {
        if (data.status) {
          console.log("create discount code success");
        } else {
          console.log("create discount code fail");
        }
        resolve(data);
      });
    });
  },
  [UPDATE_DISCOUNT_CODE](context, payload) {
    return new Promise(resolve => {
      ApiService.patch(UPDATE_DISCOUNT_CODE + "/" + payload.id, payload).then(
        ({ data }) => {
          if (data.status) {
            console.log("update discount code success");
          } else {
            console.log("update discount code fail");
          }
          resolve(data);
        }
      );
    });
  },
  [GET_DETAIL_DISCOUNT_CODE](context, payload) {
    return new Promise(resolve => {
      ApiService.get(GET_DETAIL_DISCOUNT_CODE, payload).then(({ data }) => {
        if (data.data) {
          context.commit(SET_DETAIL_DISCOUNT_INFO, data.data);
          console.log("get detail discount code success");
        } else {
          console.log("get detail discount code fail");
        }
        resolve(data);
      });
    });
  },
  [DELETE_DISCOUNT_CODE](context, payload) {
    return new Promise(resolve => {
      ApiService.delete(DELETE_DISCOUNT_CODE, payload).then(({ data }) => {
        if (data.status) {
          console.log("delete discount code success");
        } else {
          console.log("delete discount code fail");
        }
        resolve(data);
      });
    });
  },
  [GENERATE_CODE](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GENERATE_CODE, payload).then(({ data }) => {
        context.commit(SET_DISCOUNT_CODE, data);
        resolve(data);
      });
    });
  }
};

const mutations = {
  [SET_LIST_DISCOUNT_CODE](state, data) {
    state.list = data;
  },
  [SET_DISCOUNT_CODE](state, data) {
    state.code = data;
  },
  [SET_DETAIL_DISCOUNT_INFO](state, data) {
    state.codeDiscountInfo = data[0];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
