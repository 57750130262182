/* eslint no-use-before-define: 0 */
<template>
  <v-dialog
      v-model="showModel"
      scrollable
      @click:outside="onClickOutside"
      max-width="1000"
      light
      class="pa-0 ma-0"
  >
    <v-card class="pa-0 ma-0">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form ref="form" lazy-validation
                @submit.prevent="handleSubmit(saveFeedbackConfig)"
                method="post"
                id="check-feedback">
          <v-container>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1">1. {{ nameText }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                >
                <hnr-input
                    :name="'name'"
                    v-model="feedback.name"
                    :text="feedback.name"
                    :label="nameText"
                ></hnr-input>
                  <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1">2. {{ positionText }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                    name="position"
                    rules="required"
                    v-slot="{ errors }"
                >
                <hnr-input
                    :name="'position'"
                    v-model="feedback.position"
                    :text="feedback.position"
                    :label="positionText"
                ></hnr-input>
                  <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1">3. {{ feedbackText }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                    name="title"
                    rules="required"
                    v-slot="{ errors }"
                >
                <hnr-textarea
                    :name="'title'"
                    v-model="feedback.feedback"
                    :text="feedback.feedback"
                    :label="feedbackText"
                ></hnr-textarea>
                  <span class="validate-error text-danger text-danger">
                      {{ errors[0] }}
                    </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span class="text-body-1"
                >4. {{ avatarText }}</span
                >
                <div>
                  {{language.picture}}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                    name="image"
                    rules="required"
                    v-slot="{ errors }"
                >
                <v-file-input
                    :rules="rules"
                    name="image"
                    class="pl-1"
                    prepend-icon="mdi-camera"
                    v-model="image"
                    label="Tải ảnh"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="onChangeFileUpload"
                    @click:clear="clearImage"
                ></v-file-input>
                <div id="preview" v-if="show">
                  <v-img :src="feedback.image_src"></v-img>
                </div>
                <span class="validate-error text-danger">{{ imageError }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="align-center justify-center">
              <v-btn color="primary" type="submit" form="check-feedback">Lưu</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SAVE_CONF} from "@/store/setting.module";
import HnrInput from "@/components/elements/hnr-input";
import HnrTextarea from "@/components/elements/hnr-textarea.vue";

export default {
  props: {
    dialog: {type: Boolean, default: false},
    feedbackId: {type: Number, default: 0}
  },
  data() {
    return {
      rules: [
        value => !!value || 'Trường này không được bỏ trống.',
      ],
      language:{
        picture:'200px x 200px'
      },
      mandatory: true,
      image: {},
      feedback: {
        id: null,
        name: '',
        image_src: '',
        position : '',
        feedback : ''
      },
      imageError: "",
      show: false,
      edit: false,
      nameText: 'Họ Tên',
      avatarText: 'Avatar',
      positionText : 'Vị trí',
      feedbackText : 'Phản hồi',
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tạo nội dung cho Top feedback"}
    ]);
  },
  computed: {
    ...mapGetters(["imageUrl", "currentFeedback", "feedbackConf"]),
    showModel: {
      get: function () {
        return this.dialog;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (newValue) {
      }
    }
  },
  watch: {
    imageUrl() {
      this.feedback.image_src = this.imageUrl;
      if (this.feedback.image_src) {
        this.show = true;
      }
    },
    currentFeedback: {
      deep: true,
      handler(newVal) {
        this.feedback = newVal;
        if (
            this.feedback &&
            !this.feedback.feedback_list
        ) {
          this.feedback.feedback_list = [];
        }
        if (this.feedback.image_src) {
          this.show = true;
        }
      }
    }
  },
  components: {
    HnrInput,HnrTextarea
  },
  methods: {
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(UPLOAD_IMAGE, formData).then((data) => {
        if (data.data.status == 1) {
          this.$toasted.success('Upload file thành công !!', {
            position: "top-right",
            duration: 3000
          })
        }
        else {
          this.$toasted.error('File chưa đúng định dạng', {
            position: "top-right",
            duration: 3000
          })
        }
      });
    },
    clearImage() {
      this.feedback.image_src = "";
      this.show = false;
    },
    getContent(content) {
      this.feedback.feedback_content = content;
    },
    updateItemTable(payload) {
      if (this.feedback && this.feedback.feedback_list) {
        var index = this.feedback.feedback_list.findIndex(
            item => item.id == payload.id
        );
        this.feedback.feedback_list[index] = payload;
      }
    },
    addRow() {
      if (!this.feedback.feedback_list) {
        this.feedback.feedback_list = [];
      }

      var id = this.feedback.feedback_list.length;
      const item = {
        id: id,
        content: ""
      };
      this.feedback.feedback_list.push(item);
    },
    deleteItem(item) {
      var index = this.feedback.feedback_list.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.feedback.feedback_list.splice(index, 1);
        this.reindex();
      }
    },
    reindex() {
      this.feedback.feedback_list.forEach((element, index) => {
        element.id = index;
      });
    },
    checkData() {
      if (this.feedback.id === null && this.feedbackId !== null) {
        this.feedback.id = this.feedbackId;
        return true
      }
      return true
    },
    getDataPost() {
      let value = this.feedbackConf ? this.feedbackConf : [];
      value.map((slide, i) => {
        if (slide.id == this.feedback.id) {
          value[i] = this.feedback
          this.edit = true
        }
      });
      if (!this.edit) {
        value.push(this.feedback)
      }
      let data = {
        key: 'feedback',
        value: value
      }
      return data
    },
    saveFeedbackConfig() {
      let check = this.checkData()
      if (check) {
        let data = this.getDataPost()
        this.$store.dispatch(SAVE_CONF, data).then(data => {
          if (data.status) {
            this.resetForm();
            this.$emit("closePopup");
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
          }
        });
        this.edit = false
      }
    },
    onClickOutside() {
      this.resetForm();
      this.$emit("onClickOutside");
    },
    resetForm() {
      this.feedback = {
        id: null,
        title: "",
        type: 1,
        image_src: null,
        feedback_content: "",
        feedback_list: []
      };
      this.show = false;
    }
  }
};
</script>
