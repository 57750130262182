import ApiService from "@/common/api.service";
// exam meta
export const UPDATE_STATUS_ORDER = "/order/status/update";
export const UPDATE_CODE_ORDER = "/order/code/update";
export const SEND_CODE_ORDER = "/order/code/email";

export const GET_ORDER_LIST = "/order/list-course";
export const GET_ORDER_LIST_BOOK = "/order/list-book";
export const GET_ORDER_DETAIL = "/order/detail";
export const GET_ORDER_DETAIL_BOOK = "/order/detail-book";
export const SET_ORDER_LIST = "setOrderList";
export const SET_ORDER_DETAIL = "setOrderDetail";

const state = {
      orders:[],
      order : {}
}
// getters
const getters = {
      orderList(state){
            return state.orders
      } ,
      orderDetail(state){
            return state.order
      }
}
// actions
const actions = {
      async [GET_ORDER_LIST](context, payload) {
            return ApiService.query(GET_ORDER_LIST, payload).then(({ data }) => {
                  if (data.status) {
                        context.commit(SET_ORDER_LIST, data);
                  }
            })
      },
      async [GET_ORDER_LIST_BOOK](context, payload) {
            return ApiService.query(GET_ORDER_LIST_BOOK, payload).then(({ data }) => {
                  if (data.status) {
                        context.commit(SET_ORDER_LIST, data);
                  }
            })
      },
      async [GET_ORDER_DETAIL](context, payload) {
            return ApiService.query(`${GET_ORDER_DETAIL}/${payload}`, ).then(({ data }) => {
                  if (data.status) {
                        context.commit(SET_ORDER_DETAIL, data);
                  }
            })
      },
      async [GET_ORDER_DETAIL_BOOK](context, payload) {
            return ApiService.query(`${GET_ORDER_DETAIL_BOOK}/${payload}` ).then(({ data }) => {
                  if (data.status) {
                        context.commit(SET_ORDER_DETAIL, data);
                  }
            })
      },
      async [UPDATE_STATUS_ORDER](context, payload) {
            return ApiService.post(UPDATE_STATUS_ORDER, payload)
      },
      async [UPDATE_CODE_ORDER](context, payload) {
            return ApiService.post(UPDATE_CODE_ORDER, payload)
      },
      async [SEND_CODE_ORDER](context, payload) {
            return ApiService.post(SEND_CODE_ORDER, payload)
      },
}
// mutations
const mutations = {
      [SET_ORDER_LIST](state, data) {
            if (data.data != null) {
                  state.orders = data.data;
            }
      },
      [SET_ORDER_DETAIL](state, data) {
            if (data.data != null) {
                  state.order = data.data;
            }
      },
}
export default {
      state,
      getters,
      actions,
      mutations
}
