<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form ref="form" lazy-validation
            @submit.prevent="handleSubmit(saveIntroTeacherConfig)"
            method="post"
            id="banner">
      <v-container>
        <v-row>
          <v-col cols="6">
            <span class="text-body-1"
            >{{ addImg }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider
                name="image"
                rules="required"
                v-slot="{ errors }"
            >
            <v-file-input
                :name="'image'"
                :rules="rules"
                class="pl-1"
                prepend-icon="mdi-camera"
                v-model="image"
                label="Tải ảnh"
                @change="onChangeFileUpload"
                @click:clear="clearImage"
            ></v-file-input>
            </ValidationProvider>
            <div id="preview" v-if="show">
              <v-img :src="intro.image_src"></v-img>
            </div>
            <span class="validate-error text-danger">{{ imageError }}</span>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-btn color="primary" type="submit" form="banner">{{ save }}</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>
import {mapGetters} from "vuex";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {SAVE_CONF} from "@/store/setting.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  data() {
    return {
      rules: [
        value => !!value || 'Trường này không được bỏ trống.',
      ],
      show: false,
      image: {},
      intro: {
        title: "",
        image_src: null,
        content: ""
      },
      imageError: "",
      addTitle: 'Thêm title',
      addImg: 'Thêm banner (kích thước 1920px x 373px;)',
      addContent: 'Thêm nội dung giới thiệu',
      save: 'Lưu'
    };
  },
  mounted() {
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình Trang chủ", titledad: 'Cấu hình'}]);
  },
  computed: {
    ...mapGetters(["imageUrl", "bannerExam"])
  },
  watch: {
    imageUrl() {
      this.intro.image_src = this.imageUrl;
      if (this.intro.image_src) {
        this.show = true;
      }
      this.updateIntroTeacherConf();
    },
    bannerExam: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.intro = newVal;
        if (this.intro.image_src) {
          this.show = true;
        }
      }
    }
  },
  methods: {
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(UPLOAD_IMAGE, formData);
    },
    clearImage() {
      this.intro.image_src = "";
      this.show = false;
      this.updateIntroTeacherConf();
    },
    getContent(content) {
      this.intro.content = content;
      this.updateIntroTeacherConf();
    },
    updateIntroTeacherConf() {
    },
    resetIntro() {
      this.intro.title = "";
      this.intro.image_src = null;
      this.intro.content = "";
    },
    saveIntroTeacherConfig() {
      let data = {
        key: 'banner_exam',
        value: this.intro
      }
      this.$store.dispatch(SAVE_CONF, data).then((data)=>{
        if (data.status) {
          this.$toasted.success('Lưu thành công', {
            position: "top-right",
            theme: "toasted-primary",
            duration : 1000
          });
        }
      });
    }
  }
};
</script>

<style lang="scss"></style>
